export default {
  yue: [
    {
      alias: 'acupuncture',
      value: '针刺 / 针灸',
    },
    {
      alias: 'cardiology',
      value: '心脏病科',
    },
    {
      alias: 'chemical-therapy',
      value: '化学治疗',
    },
    {
      alias: 'chiropractic',
      value: '脊椎按摩疗法',
    },
    {
      alias: 'covid-testing',
      value: '新冠病毒测试',
    },
    {
      alias: 'covid-vaccine',
      value: '新冠病毒病疫苗',
    },
    {
      alias: 'dental',
      value: '牙科',
    },
    {
      alias: 'dermatology',
      value: '皮肤科',
    },
    {
      alias: 'dialysis',
      value: '透析',
    },
    {
      alias: 'durable-medical-equipment',
      value: '#N/A',
    },
    {
      alias: 'ear-nose-throat',
      value: '耳鼻喉科',
    },
    {
      alias: 'ed-divert-to-urgent-care',
      value: '#N/A',
    },
    {
      alias: 'endocrinology',
      value: '内分泌科',
    },
    {
      alias: 'gastroenterology',
      value: '消化內科',
    },
    {
      alias: 'grocery',
      value: '#N/A',
    },
    {
      alias: 'gym',
      value: '#N/A',
    },
    {
      alias: 'health-risk-assessment-physical',
      value: '健康风险评估/物理',
    },
    {
      alias: 'hospital-discharge',
      value: '出院',
    },
    {
      alias: 'imaging',
      value: '成像 / X光',
    },
    {
      alias: 'labs',
      value: '实验室',
    },
    {
      alias: 'methodone-treament',
      value: '美沙酮治疗',
    },
    {
      alias: 'nephrology',
      value: '肾脏科',
    },
    {
      alias: 'neurology',
      value: '神经内科',
    },
    {
      alias: 'nutritionist',
      value: '营养师',
    },
    {
      alias: 'oncology',
      value: '肿瘤科',
    },
    {
      alias: 'ophthalmology',
      value: '眼科',
    },
    {
      alias: 'orthopedic',
      value: '骨科',
    },
    {
      alias: 'other',
      value: '其他',
    },
    {
      alias: 'outpatient-procedure',
      value: '门诊程序',
    },
    {
      alias: 'outpatient-surgery',
      value: '门诊手术',
    },
    {
      alias: 'pharmacy',
      value: '药房',
    },
    {
      alias: 'physical-therapy',
      value: '物理治疗',
    },
    {
      alias: 'podiatrist',
      value: '足科医生',
    },
    {
      alias: 'primary-care',
      value: '家庭医生',
    },
    {
      alias: 'psychiatric',
      value: '精神病科',
    },
    {
      alias: 'pulmonology',
      value: '肺科',
    },
    {
      alias: 'senior-center',
      value: '#N/A',
    },
    {
      alias: 'snf-discharge',
      value: '从专业护理机构(SNF)',
    },
    {
      alias: 'specialist',
      value: '专科医生',
    },
    {
      alias: 'substance-abuse-program',
      value: '#N/A',
    },
    {
      alias: 'urgent-care',
      value: '紧急护理',
    },
    {
      alias: 'urologist',
      value: '泌尿外科',
    },
  ],
  cmn: [
    {
      alias: 'acupuncture',
      value: '針刺 / 針灸',
    },
    {
      alias: 'cardiology',
      value: '心髒病科',
    },
    {
      alias: 'chemical-therapy',
      value: '化學治療',
    },
    {
      alias: 'chiropractic',
      value: '脊椎按摩療法',
    },
    {
      alias: 'covid-testing',
      value: '新冠病毒測試',
    },
    {
      alias: 'covid-vaccine',
      value: '新冠病毒病疫苗',
    },
    {
      alias: 'dental',
      value: '牙科',
    },
    {
      alias: 'dermatology',
      value: '皮膚科',
    },
    {
      alias: 'dialysis',
      value: '透析',
    },
    {
      alias: 'durable-medical-equipment',
      value: '#N/A',
    },
    {
      alias: 'ear-nose-throat',
      value: '耳鼻喉科',
    },
    {
      alias: 'ed-divert-to-urgent-care',
      value: '#N/A',
    },
    {
      alias: 'endocrinology',
      value: '內分泌科',
    },
    {
      alias: 'gastroenterology',
      value: '消化內科',
    },
    {
      alias: 'grocery',
      value: '#N/A',
    },
    {
      alias: 'gym',
      value: '#N/A',
    },
    {
      alias: 'health-risk-assessment-physical',
      value: '健康風險評估/物理',
    },
    {
      alias: 'hospital-discharge',
      value: '出院',
    },
    {
      alias: 'imaging',
      value: '成像 / X光',
    },
    {
      alias: 'labs',
      value: '實驗室',
    },
    {
      alias: 'methodone-treament',
      value: '美沙酮治療',
    },
    {
      alias: 'nephrology',
      value: '腎臟科',
    },
    {
      alias: 'neurology',
      value: '神經內科',
    },
    {
      alias: 'nutritionist',
      value: '營養師',
    },
    {
      alias: 'oncology',
      value: '腫瘤科',
    },
    {
      alias: 'ophthalmology',
      value: '眼科',
    },
    {
      alias: 'orthopedic',
      value: '骨科',
    },
    {
      alias: 'other',
      value: '其他',
    },
    {
      alias: 'outpatient-procedure',
      value: '門診程序',
    },
    {
      alias: 'outpatient-surgery',
      value: '門診手術',
    },
    {
      alias: 'pharmacy',
      value: '藥房',
    },
    {
      alias: 'physical-therapy',
      value: '物理治療',
    },
    {
      alias: 'podiatrist',
      value: '足科醫生',
    },
    {
      alias: 'primary-care',
      value: '家庭醫生',
    },
    {
      alias: 'psychiatric',
      value: '精神病科',
    },
    {
      alias: 'pulmonology',
      value: '肺科',
    },
    {
      alias: 'senior-center',
      value: '#N/A',
    },
    {
      alias: 'snf-discharge',
      value: '從專業護理機構(SNF)',
    },
    {
      alias: 'specialist',
      value: '專科醫生',
    },
    {
      alias: 'substance-abuse-program',
      value: '#N/A',
    },
    {
      alias: 'urgent-care',
      value: '緊急護理',
    },
    {
      alias: 'urologist',
      value: '泌尿外科',
    },
  ],
  ko: [
    {
      alias: 'acupuncture',
      value: '한의원',
    },
    {
      alias: 'cardiology',
      value: '심장내과',
    },
    {
      alias: 'chemical-therapy',
      value: '화학 요법',
    },
    {
      alias: 'chiropractic',
      value: '카이로프랙틱',
    },
    {
      alias: 'covid-testing',
      value: '코로나 테스트',
    },
    {
      alias: 'covid-vaccine',
      value: '코로나 백신',
    },
    {
      alias: 'dental',
      value: '치과',
    },
    {
      alias: 'dermatology',
      value: '피부과',
    },
    {
      alias: 'dialysis',
      value: '신장 투석',
    },
    {
      alias: 'durable-medical-equipment',
      value: '#N/A',
    },
    {
      alias: 'ear-nose-throat',
      value: '이비인후과',
    },
    {
      alias: 'ed-divert-to-urgent-care',
      value: '#N/A',
    },
    {
      alias: 'endocrinology',
      value: '내분비내과',
    },
    {
      alias: 'gastroenterology',
      value: '소화기과',
    },
    {
      alias: 'grocery',
      value: '#N/A',
    },
    {
      alias: 'gym',
      value: '#N/A',
    },
    {
      alias: 'health-risk-assessment-physical',
      value: '검강검진',
    },
    {
      alias: 'hospital-discharge',
      value: '퇴원',
    },
    {
      alias: 'imaging',
      value: 'X-ray CT촬영',
    },
    {
      alias: 'labs',
      value: '피검사',
    },
    {
      alias: 'methodone-treament',
      value: '메타돈 치료',
    },
    {
      alias: 'nephrology',
      value: '신장내과',
    },
    {
      alias: 'neurology',
      value: '신경내과',
    },
    {
      alias: 'nutritionist',
      value: '영양사',
    },
    {
      alias: 'oncology',
      value: '암치료',
    },
    {
      alias: 'ophthalmology',
      value: '안과',
    },
    {
      alias: 'orthopedic',
      value: '정형외과',
    },
    {
      alias: 'other',
      value: '그 외',
    },
    {
      alias: 'outpatient-procedure',
      value: '외래 시술',
    },
    {
      alias: 'outpatient-surgery',
      value: '외래 수술',
    },
    {
      alias: 'pharmacy',
      value: '약국',
    },
    {
      alias: 'physical-therapy',
      value: '물리 치료',
    },
    {
      alias: 'podiatrist',
      value: '족부 족관절 전문의',
    },
    {
      alias: 'primary-care',
      value: '주치의',
    },
    {
      alias: 'psychiatric',
      value: '정신과',
    },
    {
      alias: 'pulmonology',
      value: '호흡기내과',
    },
    {
      alias: 'senior-center',
      value: '#N/A',
    },
    {
      alias: 'snf-discharge',
      value: '요양원 퇴원',
    },
    {
      alias: 'specialist',
      value: '전문의',
    },
    {
      alias: 'substance-abuse-program',
      value: '#N/A',
    },
    {
      alias: 'urgent-care',
      value: '어전트케어',
    },
    {
      alias: 'urologist',
      value: '비뇨기과',
    },
  ],
  vi: [
    {
      alias: 'acupuncture',
      value: 'Châm cứu',
    },
    {
      alias: 'cardiology',
      value: 'Tim mạch',
    },
    {
      alias: 'chemical-therapy',
      value: 'Liệu pháp hóa học',
    },
    {
      alias: 'chiropractic',
      value: 'Nắn khớp xương',
    },
    {
      alias: 'covid-testing',
      value: 'Kiểm tra COVID',
    },
    {
      alias: 'covid-vaccine',
      value: 'Vắc-xin phòng ngừa covid',
    },
    {
      alias: 'dental',
      value: 'Tiệm thuốc tây',
    },
    {
      alias: 'dermatology',
      value: 'Da liễu',
    },
    {
      alias: 'dialysis',
      value: 'Lọc máu',
    },
    {
      alias: 'durable-medical-equipment',
      value: '#N/A',
    },
    {
      alias: 'ear-nose-throat',
      value: 'Tai họng',
    },
    {
      alias: 'ed-divert-to-urgent-care',
      value: '#N/A',
    },
    {
      alias: 'endocrinology',
      value: 'Khoa nội tiết',
    },
    {
      alias: 'gastroenterology',
      value: 'Khoa tiêu hóa',
    },
    {
      alias: 'grocery',
      value: '#N/A',
    },
    {
      alias: 'gym',
      value: '#N/A',
    },
    {
      alias: 'health-risk-assessment-physical',
      value: 'Đánh giá rủi ro sức khỏe /',
    },
    {
      alias: 'hospital-discharge',
      value: 'Xuất viện',
    },
    {
      alias: 'imaging',
      value: 'Khoa chụp hình',
    },
    {
      alias: 'labs',
      value: 'Phòng thí nghiệm',
    },
    {
      alias: 'methodone-treament',
      value: 'Điều trị Methadone',
    },
    {
      alias: 'nephrology',
      value: 'Khỏa thận',
    },
    {
      alias: 'neurology',
      value: 'Khoa thần kinh',
    },
    {
      alias: 'nutritionist',
      value: 'Khoa dinh dưỡng',
    },
    {
      alias: 'oncology',
      value: 'Ung thư',
    },
    {
      alias: 'ophthalmology',
      value: 'Nhãn khoa',
    },
    {
      alias: 'orthopedic',
      value: 'Chỉnh hình',
    },
    {
      alias: 'other',
      value: 'Khác',
    },
    {
      alias: 'outpatient-procedure',
      value: 'Thủ tục ngoại trú',
    },
    {
      alias: 'outpatient-surgery',
      value: 'Phẫu thuật ngoại trú',
    },
    {
      alias: 'pharmacy',
      value: 'Tiệm thuốc tây',
    },
    {
      alias: 'physical-therapy',
      value: 'Vật lý trị liệu',
    },
    {
      alias: 'podiatrist',
      value: 'Bác sĩ nhi khoa',
    },
    {
      alias: 'primary-care',
      value: 'Khám bệnh chính',
    },
    {
      alias: 'psychiatric',
      value: 'Khoa tâm thần',
    },
    {
      alias: 'pulmonology',
      value: 'Công nghệ mạch máu',
    },
    {
      alias: 'senior-center',
      value: '#N/A',
    },
    {
      alias: 'snf-discharge',
      value: 'Xuất SNF',
    },
    {
      alias: 'specialist',
      value: 'Khám chuyên môn',
    },
    {
      alias: 'substance-abuse-program',
      value: '#N/A',
    },
    {
      alias: 'urgent-care',
      value: 'Chăm sóc đặc biệt',
    },
    {
      alias: 'urologist',
      value: 'Khoa tiết niệu',
    },
  ],
  es: [
    {
      alias: 'acupuncture',
      value: 'Acupuntura',
    },
    {
      alias: 'cardiology',
      value: 'Cardiología',
    },
    {
      alias: 'chemical-therapy',
      value: 'Terapia química',
    },
    {
      alias: 'chiropractic',
      value: 'Quiropráctica',
    },
    {
      alias: 'covid-testing',
      value: 'Prueba de COVID',
    },
    {
      alias: 'covid-vaccine',
      value: 'Vacuna para el COVID-19',
    },
    {
      alias: 'dental',
      value: 'Dental',
    },
    {
      alias: 'dermatology',
      value: 'Dermatología',
    },
    {
      alias: 'dialysis',
      value: 'Diálisis',
    },
    {
      alias: 'durable-medical-equipment',
      value: '#N/A',
    },
    {
      alias: 'ear-nose-throat',
      value: 'Otorrinolaringología',
    },
    {
      alias: 'ed-divert-to-urgent-care',
      value: '#N/A',
    },
    {
      alias: 'endocrinology',
      value: 'Endocrinología',
    },
    {
      alias: 'gastroenterology',
      value: 'Gastroenterología',
    },
    {
      alias: 'grocery',
      value: '#N/A',
    },
    {
      alias: 'gym',
      value: '#N/A',
    },
    {
      alias: 'health-risk-assessment-physical',
      value: 'Evaluación de riesgos para la salud / física',
    },
    {
      alias: 'hospital-discharge',
      value: 'Alta hospitalaria',
    },
    {
      alias: 'imaging',
      value: 'Imagen',
    },
    {
      alias: 'labs',
      value: 'Laboratorios',
    },
    {
      alias: 'methodone-treament',
      value: 'Tratamiento con metadona',
    },
    {
      alias: 'nephrology',
      value: 'Nefrología',
    },
    {
      alias: 'neurology',
      value: 'Neurología',
    },
    {
      alias: 'nutritionist',
      value: 'Nutricionista',
    },
    {
      alias: 'oncology',
      value: 'Oncología',
    },
    {
      alias: 'ophthalmology',
      value: 'Oftalmología',
    },
    {
      alias: 'orthopedic',
      value: 'Ortopédico',
    },
    {
      alias: 'other',
      value: 'Otro',
    },
    {
      alias: 'outpatient-procedure',
      value: 'Procedimiento ambulatorio',
    },
    {
      alias: 'outpatient-surgery',
      value: 'Cirugía ambulatoria',
    },
    {
      alias: 'pharmacy',
      value: 'Farmacia',
    },
    {
      alias: 'physical-therapy',
      value: 'Terapia física',
    },
    {
      alias: 'podiatrist',
      value: 'Podólogo',
    },
    {
      alias: 'primary-care',
      value: 'Visita de atención primaria',
    },
    {
      alias: 'psychiatric',
      value: 'Psiquiátrico',
    },
    {
      alias: 'pulmonology',
      value: 'Pulmonología',
    },
    {
      alias: 'senior-center',
      value: '#N/A',
    },
    {
      alias: 'snf-discharge',
      value: 'Alta del Centro de Enfermería Especializada',
    },
    {
      alias: 'specialist',
      value: 'Visita al especialista',
    },
    {
      alias: 'substance-abuse-program',
      value: '#N/A',
    },
    {
      alias: 'urgent-care',
      value: 'Atención de urgencias',
    },
    {
      alias: 'urologist',
      value: 'Urología',
    },
  ],
  en: [
    {
      alias: 'acupuncture',
      value: 'Acupuncture',
    },
    {
      alias: 'cardiology',
      value: 'Cardiology (Heart)',
    },
    {
      alias: 'chemical-therapy',
      value: 'Chemical Therapy',
    },
    {
      alias: 'chiropractic',
      value: 'Chiropractic',
    },
    {
      alias: 'covid-testing',
      value: 'COVID Testing',
    },
    {
      alias: 'covid-vaccine',
      value: 'COVID Vaccine',
    },
    {
      alias: 'dental',
      value: 'Dental',
    },
    {
      alias: 'dermatology',
      value: 'Dermatology (Skin)',
    },
    {
      alias: 'dialysis',
      value: 'Dialysis',
    },
    {
      alias: 'durable-medical-equipment',
      value: 'Durable Medical Equipment (DME)',
    },
    {
      alias: 'ear-nose-throat',
      value: 'Ear, Nose & Throat',
    },
    {
      alias: 'ed-divert-to-urgent-care',
      value: 'ED divert to Urgent Care',
    },
    {
      alias: 'endocrinology',
      value: 'Endocrinology (Hormone)',
    },
    {
      alias: 'gastroenterology',
      value: 'Gastroenterology (Digestive/Stomach)',
    },
    {
      alias: 'grocery',
      value: 'Grocery',
    },
    {
      alias: 'gym',
      value: 'Gym',
    },
    {
      alias: 'health-risk-assessment-physical',
      value: 'Health Risk Assessment / Physical',
    },
    {
      alias: 'hospital-discharge',
      value: 'Hospital Discharge',
    },
    {
      alias: 'imaging',
      value: 'Imaging',
    },
    {
      alias: 'labs',
      value: 'Labs',
    },
    {
      alias: 'methodone-treament',
      value: 'Methodone Treament',
    },
    {
      alias: 'nephrology',
      value: 'Nephrology (Kidney)',
    },
    {
      alias: 'neurology',
      value: 'Neurology (Brain)',
    },
    {
      alias: 'nutritionist',
      value: 'Nutritionist',
    },
    {
      alias: 'oncology',
      value: 'Oncology (Cancer)',
    },
    {
      alias: 'ophthalmology',
      value: 'Ophthalmology',
    },
    {
      alias: 'orthopedic',
      value: 'Orthopedic',
    },
    {
      alias: 'other',
      value: 'Other',
    },
    {
      alias: 'outpatient-procedure',
      value: 'Outpatient Procedure',
    },
    {
      alias: 'outpatient-surgery',
      value: 'Outpatient Surgery',
    },
    {
      alias: 'pharmacy',
      value: 'Pharmacy',
    },
    {
      alias: 'physical-therapy',
      value: 'Physical Therapy',
    },
    {
      alias: 'podiatrist',
      value: 'Podiatrist',
    },
    {
      alias: 'primary-care',
      value: 'Primary Care Visit',
    },
    {
      alias: 'psychiatric',
      value: 'Psychiatric',
    },
    {
      alias: 'pulmonology',
      value: 'Pulmonology (Lungs)',
    },
    {
      alias: 'senior-center',
      value: 'Senior Center',
    },
    {
      alias: 'snf-discharge',
      value: 'SNF Discharge',
    },
    {
      alias: 'specialist',
      value: 'Specialist Visit',
    },
    {
      alias: 'substance-abuse-program',
      value: 'Substance Abuse Program',
    },
    {
      alias: 'urgent-care',
      value: 'Urgent Care',
    },
    {
      alias: 'urologist',
      value: 'Urologist',
    },
  ],
};
