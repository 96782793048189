export const yue = {
  translation: {
    selectLanguage: '請選擇您的語言',
    scheduleYourRide: '讓我們預約您的行程！',
    importantNote: '如果這是醫療緊急情況，請立即撥打 911',
    ifYouAppointment: '如果您的預約時間不到 24 小時，請致電 進行電話預約 ',
    selectHealthInsurance: '選擇您的健康保險',
    selectPurpose: '請選擇行程目的',
    provideContactDetails: '請提供您的聯繫方式',
    provideDropOffTime: '請提供您的預約日期和時間',
    providePickUpAddress: '請輸入上車地點',
    provideDropOffAddress: '請輸入您的目的地',
    provideId: '請提供您的會員ID',
    provideDetails: '請提供您的會員資料',
    appointmentHint: '如果您的預約時間不到 24 小時，請致電 844-743-4344 進行電話預約',
    next: '下一個',
    back: '後退',
    locateId: '在保險卡上找到您的會員 ID',
    selectOrType: '在此處輸入或選擇一項',
    phoneNumber: '電話號碼',
    landline: '座機號碼',
    mobile: '手機號碼',
    email: '電子郵件',
    memberId: '會員ID',
    firstName: '名',
    secondName: '别名',
    birthDate: '出生日期',
    start: '開始',
    date: '日期',
    time: 'Time',
    timezone: '時區',
    enterValidDate: '輸入一個有效日期',
    enterValidNumber: '請輸入一個有效的電話號碼',
    enterValidEmail: '請輸入有效的電子郵件',
    pleaseChooseLocation: '請選擇地點',
    pleaseEnterAdress: '請輸入地址',
    pleaseEnterDestinationAddress: '請輸入您的目的地',
    pleaseEnterCity: '請輸入城市',
    pleaseEnterState: '請輸入州',
    pleaseEnterZipCode: '請輸入郵區編碼',
    invalidmemberId: '請提供您的會員ID',
    yourFirstName: '您的名字',
    yourSecondName: '您的別名',
    searchForAddress: '搜索地址',
    inputAddressHere: '請輸入地址',
    addressLine1: '街道地址',
    addressLine2: '公寓/房間/其他',
    addressLine2Placeholder: '公寓、大樓、單元等',
    city: '城市',
    state: '州',
    zip: '郵政編碼',
    textHome: '地址名稱',
    placeholderHome: '舉例：家',
    placeholderHospital: '例如醫院',
    ex: '例子',
    ext: '分機號',
    cancel: '取消',
    optional: ' (可選的)',
    primaryCompanionName: '主要同伴姓名',
    relationship: '關係',
    contactNumber: '聯繫電話',
    requireAssistance: '您需要幫助嗎?',
    selectTripType: '單程或往返？',
    roundTrip: '往返',
    roundTripPickUpTime: '您希望什麼時候接您的預約？',
    oneWay: '單程',
    willCall: '會打電話',
    willCallHint1: '如果您想在準備好的時候打電話，請選擇“會打電話”。',
    willCallHint2: `注意：等待時間取決於供應商的可用性`,
    deviceEquipment: '您在使用的設備或儀器',
    numberOfCompanions: '同伴人數',
    none: '沒有',
    one: '一',
    two: '二',
    verifyTripDetails: '驗證行程詳情',
    submit: '提交',
    healthInsuarance: '健康保險',
    insuranceId: '會員ID',
    companions: '同伴',
    name: '名稱',
    member: '成員',
    memberInfo: '會員信息',
    assistance: '幫助',
    suggestedTime: '建議取車時間',
    unavailablePlan: `不幸的是，CareCar 目前不適用於您的健康保險計劃。請聯繫您的健康保險公司。`,
    oneWayTrip: '單程旅行',
    copy: '複製',
    print: '打印',
    pleaseSelectOne: '請選擇一項',
    fieldCantBeEmpty: '此字段不能為空',
    enterValidData2: '請輸入一個有效的值。此字段不完整或日期無效',
    roundTripHint: '目前，我們僅提供返回您上車地點的乘車服務',
    firstTrip: '第一次旅行',
    returnTrip: '回程',
    pickup: '撿起',
    dropoff: '下車地點',
    youChooseWillCall: `你選擇“我會打電話”`,
    noteWaitingTime: `注意：等待時間取決於供應商的可用性`,
    basedOnADistanceOf: '基於此趟行程距離',
    withATravelTimeOf: '行程大約時間',
    forAnAppointmentOf: '預約時間為',
    miles: '英里',
    minutes: '分鐘',
    cancelWarning: '如果繼續，所有輸入的數據都將丟失。',
    returnPickupTime: '您選擇的回程接送時間',
    tripDetails: '行程詳情',
    enterPickupTime: '此字段不能為空',
    successText: '您的請求已成功提交。 ',
    succesText2: ' 請保存此信息以作記錄。',
    notes: '筆記',
    notesPlaceholder:
      '登機口代碼是 1234 / 2 號樓 / 我需要一些幫助將我的助行器卸到車上 / 在我提供的地址前面見你，在郵箱旁邊 / 我戴著一頂藍帽子 / 另外，它在街道的北邊',
    enterManualAddress: '點擊此處手動輸入地址',
    companionAge: '同伴必須年滿 18 歲',
    other: '其他',
    note2Min: '注意：大約需要 2 分鐘',
    pleaseSpecify: '請明確說明',
    placeholderSpecify: '健身房、雜貨店等',
    notesTip: '註釋幫助我們提供最高水平的服務',
    referenceNumber: '您的參考號',
    phoneNumberTip: '如果您代表其他人安排時間，請輸入在預約當天聯繫到會員的最佳電話號碼',
    enterLastFiveDigits: '請輸入最後 5 位數字',
  },
};
export default yue;
