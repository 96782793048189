export const ko = {
  translation: {
    selectLanguage: '언어를 선택하세요',
    scheduleYourRide: '당신의 여행을 예약합시다!',
    importantNote: '의료 응급 상황인 경우 즉시 911에 전화하십시오',
    ifYouAppointment: '만약 예약 시간이 24시간 미만일 경우 로 전화 주세요 ',
    selectHealthInsurance: '보험 회사를 선택하세요',
    selectPurpose: '방문 목적을 선택해주세요',
    provideContactDetails: '연락처를 알려주세요',
    provideDropOffTime: '예약 날짜와 시간 또는 원하는 귀가 시간을 입력하세요',
    providePickUpAddress: '픽업 주소를 입력하세요',
    provideDropOffAddress: '목적지 주소를 입력하세요',
    provideId: '회원 아이디를 입력하여 주세요',
    provideDetails: '회원정보를 입력해주세요',
    appointmentHint: '만약 예약 시간이 24시간 미만일 경우 844-743-4344 로 전화 주세요',
    locateId: '보험 카드에서 회원 ID 찾기',
    next: '뒤로가기',
    back: '다음',
    selectOrType: '여기에 입력하거나 하나를 선택하세요',
    phoneNumber: '전화 번호',
    landline: '집전화',
    mobile: '핸드폰',
    email: '이메일',
    memberId: '회원 ID',
    firstName: '이름',
    secondName: '두 번째 이름',
    birthDate: '생일',
    start: '시작',
    date: '날짜',
    time: '시간',
    timezone: '시간대',
    enterValidDate: '유효한 날짜를 입력하세요',
    enterValidNumber: '유효한 전화 번호를 입력하세요',
    enterValidEmail: '유효한 이메일을 입력하세요',
    pleaseChooseLocation: '위치를 선택하세요',
    pleaseEnterDestinationAddress: '목적지 주소를 입력하세요',
    pleaseEnterAdress: '주소를 입력하세요',
    pleaseEnterCity: '도시를 입력하세요',
    pleaseEnterState: '주를 입력하세요',
    pleaseEnterZipCode: '우편번호를 입력하세요',
    invalidmemberId: '회원 아이디를 입력하여 주세요',
    yourFirstName: '당신의 이름',
    yourSecondName: '두 번째 이름',
    searchForAddress: '주소 검색',
    inputAddressHere: '여기에 주소를 입력하세요',
    addressLine1: '주소 라인 1',
    addressLine2: '주소 라인 2',
    addressLine2Placeholder: '아파트, 건물, 유닛 등',
    city: '도시',
    state: '주',
    zip: '우편 번호',
    textHome: '주소 명',
    placeholderHome: '전. 집',
    ex: '예',
    ext: 'Ext.',
    cancel: '취소',
    optional: ' (선택 사항)',
    primaryCompanionName: '동행자 이름',
    relationship: '관계',
    contactNumber: '연락처',
    requireAssistance: '도움이 필요하십니까?',
    selectTripType: '편도 또는 왕복?',
    deviceEquipment: '장치 및 장비',
    roundTrip: '왕복',
    roundTripPickUpTime: '약속 시간에서 몇시에 픽업하시겠습니까?',
    oneWay: '편도',
    willCall: '통화할 것입니다',
    willCallHint1: '준비 되셨을때 전화 하시길 원하시면 "통화할 것입니다" 를 선택 하세요.',
    willCallHint2: `시간이 소요 될수 있습니다`,
    numberOfCompanions: '동반자 수',
    none: '없음',
    one: '하나',
    two: '둘',
    verifyTripDetails: '여행 세부 정보 확인',
    submit: '제출하다',
    healthInsuarance: '건강 보험',
    insuranceId: '회원 ID',
    companions: '동반자',
    name: '이름',
    member: '회원',
    memberInfo: '회원정보',
    assistance: '보조',
    suggestedTime: '추천 픽업 시간',
    unavailablePlan:
      '유감스럽게도 CareCar는 현재 귀하의 건강 보험 플랜과 협력하지 않습니다. 건강 보험 회사에 문의하십시오.',
    oneWayTrip: '편도',
    copy: '복사',
    print: '인쇄',
    pleaseSelectOne: '하나를 골라주세요',
    fieldCantBeEmpty: '이 필드는 비워둘 수 없습니다',
    enterValidData2: '정보를 입력 하세요. 이 필드는 비워둘 수 없습니다',
    roundTripHint: '현재는 귀가 서비스만 제공하고 있습니다',
    firstTrip: '첫번째 여행',
    returnTrip: '왕복 여행',
    pickup: '픽업',
    dropoff: '하차 장소',
    youChooseWillCall: `당신은 '내가 전화할게'를 선택`,
    noteWaitingTime: `참고: 대기 시간은 제공업체의 가용성에 따라 다릅니다.`,
    basedOnADistanceOf: '거리',
    withATravelTimeOf: '주행시간',
    forAnAppointmentOf: '예약시간',
    miles: '마일',
    minutes: '분',
    cancelWarning: '계속 진행하면 입력한 데이터가 모두 손실됩니다.',
    returnPickupTime: '선택한 반납 시간',
    placeholderHospital: '예시 병원',
    tripDetails: '건강 보험',
    enterPickupTime: '이 필드는 비워둘 수 없습니다',
    successText: '귀하의 요청이 성공적으로 제출되었습니다. ',
    succesText2: ' 기록을 위해 이 정보를 저장하십시오.',
    notes: '메모',
    notesPlaceholder:
      '게이트 코드는 1234입니다. / 2번 건물 / 워커를 차에 내리는 데 도움이 필요합니다. / 제가 제공한 주소 앞에서 바로 만나요, 우편함 옆에 / 파란 모자를 쓰고 있어요 / 또한, 그것은 거리의 북쪽에있다',
    enterManualAddress: '수동으로 주소를 입력하려면 여기를 클릭하십시오',
    companionAge: '동반자는 18세 이상이어야 합니다.',
    other: '그 외',
    note2Min: '참고: 약 2분 소요',
    pleaseSpecify: '명시 해주세요',
    placeholderSpecify: '체육관, 식료품점 등',
    notesTip: '메모는 가능한 최고 수준의 서비스를 제공하는 데 도움이 됩니다.',
    referenceNumber: '참조 번호',
    phoneNumberTip:
      '다른 사람을 대신하여 예약하는 경우 예약 당일 회원에게 가장 잘 닿는 번호를 입력하세요.',
    enterLastFiveDigits: '마지막 5자리를 입력하세요.',
  },
};
export default ko;
