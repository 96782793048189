/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { useFormik } from 'formik';
import { Input, Button, Checkbox, MaskedInput } from '../../../../components';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../types/stores';
import * as yup from 'yup';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import { validateString } from '../../../../utils';
import commonStyles from '../../../../styles/common.module.scss';
import ReactTooltip from 'react-tooltip';
import 'yup-phone';
import tipIcon from '../../../../assets/icons/tip.svg';

interface Props {
  onClickBack: () => void;
  onClickNext: () => void;
  setValue: (value: any, fieldName: string) => void;
}

const ContactDetailsForm: React.FC<Props> = ({ onClickBack, onClickNext, setValue }) => {
  const { phoneNumber, email, isMobile } = useSelector((state: AppState) => state);
  const matches = useMediaQuery('(max-width: 576px)');
  const { t } = useTranslation();
  const formik = useFormik({
    validationSchema: yup.object({
      phoneNumber: yup
        .string()
        .phone('US', true, t('enterValidNumber'))
        .min(10, t('enterValidNumber'))
        .required(t('fieldCantBeEmpty')),
      email: yup.string().email(t('enterValidEmail')),
    }),
    initialValues: {
      phoneNumber: phoneNumber || '',
      email: email || '',
      checkLandline: false,
      checkMobile: isMobile,
    },
    onSubmit: () => {
      setValue(formik.values.phoneNumber, 'phoneNumber');
      setValue(formik.values.checkMobile, 'isMobile');
      setValue(formik.values.email, 'email');
      onClickNext();
    },
  });

  const changeValueLandline = () => {
    formik.values.checkMobile && formik.setFieldValue('checkMobile', false);
    formik.setFieldValue('checkLandline', true);
  };

  const changeValueMobile = () => {
    formik.values.checkLandline && formik.setFieldValue('checkLandline', false);
    formik.setFieldValue('checkMobile', true);
  };

  const renderTipContent = () => (
    <div>
      <p className={styles.notesText}>{t('phoneNumberTip')}</p>
    </div>
  );

  const renderPhoneNumberTip = () => (
    <>
      <ReactTooltip getContent={renderTipContent} />
      <img
        src={tipIcon}
        data-tip="tooltip"
        data-type="light"
        data-class={commonStyles.tipContainer}
        className={styles.tipContainerMargin}
      />
    </>
  );

  return (
    <div className={styles.wrap}>
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          spacing={1}
          sx={{ justifyContent: 'center', padding: matches ? `0 4vw 4vh` : 0 }}
        >
          <Grid item xs={12}>
            <p className={commonStyles.boldText}>{t('provideContactDetails')}</p>
          </Grid>
          <Grid item xs={matches ? 12 : 6}>
            <MaskedInput
              text={t('phoneNumber')}
              placeholder="(555) 000-0000"
              id="phoneNumber"
              name="phoneNumber"
              mask="(999) 999-9999"
              sx={{ width: '100%' }}
              value={formik.values.phoneNumber}
              onBlur={formik.handleBlur}
              onChange={(e: any) => {
                const value = e.target.value || '';
                formik.setFieldValue('phoneNumber', validateString(value));
              }}
              error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
              helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
              renderTip={renderPhoneNumberTip}
            />
          </Grid>
          <Grid item xs={matches ? 12 : 6.5}>
            <div className={` ${commonStyles.wrapCheckbox} ${styles.marginCheckbox}`}>
              <div className={commonStyles.checkbox}>
                <Checkbox
                  value={formik.values.checkMobile}
                  onChange={changeValueMobile}
                  id="checkMobile"
                  name="checkMobile"
                />
                <p>{t('mobile')}</p>
              </div>
              <div className={commonStyles.checkbox}>
                <Checkbox
                  value={formik.values.checkLandline}
                  onChange={changeValueLandline}
                  id="checkLandline"
                  name="checkLandline"
                />
                <p>{t('landline')}</p>
              </div>
            </div>
          </Grid>
          <Grid item xs={matches ? 12 : 6}>
            <Input
              placeholder="email@example.com"
              isOptional
              text={t('email')}
              id="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              onBlur={formik.handleBlur}
              sx={{ width: '100%' }}
            />
          </Grid>
        </Grid>
        <div className={commonStyles.wrapButton}>
          <Button label={t('back')} outlined onClick={onClickBack} />
          <Button label={t('next')} />
        </div>
      </form>
    </div>
  );
};

export default ContactDetailsForm;
