export const cmn = {
  translation: {
    selectLanguage: '请选择您的语言',
    scheduleYourRide: '让我们预约您的行程!',
    importantNote: '如果这是医疗紧急情况，请立即拨打 911',
    ifYouAppointment: '如果您的预约时间不到 24 小时，请致电 进行电话预约 ',
    selectHealthInsurance: '选择您的健康保险',
    selectPurpose: '请选择行程目的',
    provideContactDetails: '请提供您的联系方式',
    provideDropOffTime: '请提供您的预约日期和时间',
    providePickUpAddress: '请输入上车地点',
    provideDropOffAddress: '请输入您的目的地',
    provideId: '请提供您的会员ID',
    provideDetails: '请提供您的会员资料',
    appointmentHint: '如果您的预约时间不到 24 小时，请致电 844-743-4344 进行电话预约',
    next: '下一个',
    back: '后退',
    locateId: '在保险卡上找到您的会员 ID',
    selectOrType: '在此处输入或选择一项',
    phoneNumber: '电话号码',
    landline: '座机号码',
    mobile: '手机号码',
    email: '电子邮件 (可选的)',
    memberId: '会员ID',
    firstName: '名',
    secondName: ' 别名',
    birthDate: '出生日期',
    start: '开始',
    date: '日期',
    time: '时间',
    timezone: '时区',
    enterValidDate: '输入一个有效日期',
    enterValidNumber: '请输入一个有效的电话号码',
    enterValidEmail: '请输入有效的电子邮件',
    pleaseChooseLocation: '请选择地点',
    pleaseEnterAdress: '请输入地址',
    pleaseEnterDestinationAddress: '请输入您的目的地',
    pleaseEnterCity: '请输入地址',
    pleaseEnterState: '请输入州',
    pleaseEnterZipCode: '请输入邮区编码',
    invalidmemberId: '请提供您的会员ID',
    yourFirstName: '您的名字',
    yourSecondName: '您的别名',
    searchForAddress: '搜索地址',
    inputAddressHere: '请输入地址',
    addressLine1: '街道地址',
    addressLine2: '公寓/房间/其他',
    addressLine2Placeholder: '公寓、大楼、单元等',
    city: '城市',
    state: '州',
    zip: '邮区编码',
    textHome: '地址名称',
    placeholderHome: '举例：家',
    placeholderHospital: '例如医院',
    ex: '例子.',
    ext: '分机号（可选的）',
    cancel: '取消',
    optional: ' (可选的)',
    primaryCompanionName: '主要同伴姓名',
    relationship: '关系',
    contactNumber: '联系电话',
    requireAssistance: '您需要帮助吗？ （可选的）',
    selectTripType: '单程或往返？',
    roundTrip: '往返',
    roundTripPickUpTime: '您希望什么时候接您的预约？',
    oneWay: '单程',
    willCall: '会打电话',
    willCallHint1: '如果您想在您准备好后再给我们打电话，请选择“会打电话”.',
    willCallHint2: `请注意：等待司机到达的时间取决于当时该区域中司机的数量和距离`,
    deviceEquipment: '您在使用的设备或仪器',
    numberOfCompanions: '同伴人数',
    none: '没有',
    one: '一',
    two: '二',
    verifyTripDetails: '验证行程详情',
    submit: '提交',
    healthInsuarance: '健康保险',
    insuranceId: '会员ID',
    companions: '同伴',
    name: '名称',
    member: '成员',
    memberInfo: '会员信息',
    assistance: '帮助',
    suggestedTime: '建议上车时间',
    unavailablePlan: `不幸的是，CareCar 目前不适用于您的健康保险计划。请联系您的健康保险公司。`,
    oneWayTrip: '单程旅行',
    copy: '复制',
    print: '打印',
    pleaseSelectOne: '请选择其中一项',
    fieldCantBeEmpty: '此字段不能为空',
    enterValidData2: '请输入一个有效的值。此字段不完整或日期无效',
    roundTripHint: '目前，我们仅提供返回您上车地点的乘车服务',
    firstTrip: '第一次旅行',
    returnTrip: '回程',
    pickup: '捡起',
    dropoff: '下车地点',
    youChooseWillCall: `你选择“我会打电话”`,
    noteWaitingTime: `注意：等待时间取决于供应商的可用性`,
    basedOnADistanceOf: '基于此趟行程距离',
    withATravelTimeOf: '行程大约时间',
    forAnAppointmentOf: '预约时间为',
    miles: '英里',
    minutes: '分钟',
    cancelWarning: '如果继续，所有输入的数据都将丢失。',
    returnPickupTime: '您选择的回程接送时间',
    tripDetails: '行程详情',
    enterPickupTime: '此字段不能为空',
    successText: '您的请求已成功提交。',
    succesText2: ' 请保存此信息以作记录。',
    notes: '笔记',
    notesPlaceholder:
      '登机口代码是 1234 / 2 号楼 / 我需要一些帮助将我的助行器卸到车上 / 在我提供的地址前面见你，在邮箱旁边 / 我戴着一顶蓝帽子 / 另外，它在街道的北边',
    enterManualAddress: '点击此处手动输入地址',
    companionAge: '同伴必须年满 18 岁',
    other: '其他',
    note2Min: '注意：大约需要 2 分钟',
    pleaseSpecify: '请明确说明',
    placeholderSpecify: '健身房、杂货店等',
    notesTip: '注释帮助我们提供最高水平的服务',
    referenceNumber: '您的参考号',
    phoneNumberTip: '如果您代表其他人安排时间，请输入在预约当天联系到会员的最佳电话号码',
    enterLastFiveDigits: '请输入最后 5 位数字',
  },
};
export default cmn;
