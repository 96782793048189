export const vi = {
  translation: {
    selectLanguage: 'Xin chọn ngôn ngữ của bạn',
    scheduleYourRide: 'Hãy lên lịch cho chuyến đi của bạn!',
    importantNote: 'Nếu day la trường hợp y tế khẩn cấp, xin gọi 911 ngày lập tức',
    ifYouAppointment: `Nếu cuộc hẹn của bạn ít hơn 24 
      gio ke tu bay gio, xin vui lòng lấy 
      cuộc hẹn qua phone`,
    selectHealthInsurance: 'Chọn bảo hiểm sức khỏe của bạr',
    selectPurpose: 'Vui lòng chọn mục đích chuyến thăm của bạn',
    provideContactDetails: 'Vui lòng cung cấp chi tiết liên hệ của bạn',
    provideDropOffTime:
      'Vui lòng cung cấp ngày, giờ cuộc hẹn của bạn hoặc địa điểm trả khách mà bạn thích.',
    providePickUpAddress: 'Vui lòng nhập địa chỉ để đón bạn',
    provideDropOffAddress: 'Vui lòng nhập địa chỉ đến của bạn',
    provideId: 'Vui lòng cung cấp ID thành viên của bạn',
    provideDetails: 'Vui lòng cung cấp chi tiết thành viên của bạn',
    appointmentHint: `Nếu cuộc hẹn của bạn ít hơn 24 
      gio ke tu bay gio, xin vui lòng lấy 
      cuộc hẹn qua phone 844-743-4344`,
    next: 'kế tiếp',
    locateId: 'Tìm ID thành viên của bạn trên thẻ bảo hiểm',
    back: 'trở lại',
    selectOrType: 'nhấp vào đây hoặc chọn một',
    phoneNumber: 'Số điện thoại',
    landline: 'Điện thoại cố định',
    mobile: 'Điện thoại di động',
    email: 'E-mail',
    memberId: 'Mã thành viên',
    firstName: 'Tên',
    secondName: 'Họ',
    birthDate: 'Ngày sinh',
    start: 'bắt đầu ',
    date: 'Ngày',
    time: 'Thời gian',
    timezone: 'Múi giờ',
    enterValidDate: 'Nhập một ngày hợp lệ',
    enterValidNumber: 'Xin vui lòng nhập một số điện thoại hợp lệ',
    enterValidEmail: 'Vui lòng nhập email hợp lệ',
    pleaseChooseLocation: 'Vui lòng chọn vị trí',
    pleaseEnterDestinationAddress: 'Vui lòng nhập địa chỉ đích của bạn',
    pleaseEnterAdress: 'Vui lòng nhập địa chỉ',
    pleaseEnterCity: 'Vui lòng nhập thành phố',
    pleaseEnterState: 'Vui lòng nhập tiểu bang',
    pleaseEnterZipCode: 'Vui lòng nhập mã zip',
    invalidmemberId: 'Vui lòng nhập ID thành viên hợp lệ',
    yourFirstName: 'Tên của bạn',
    yourSecondName: 'Tên thứ hai của bạn',
    searchForAddress: 'Tìm kiếm địa chỉ',
    inputAddressHere: 'Nhập địa chỉ tại đây',
    addressLine1: 'Dòng địa chỉ 1',
    addressLine2: 'Địa chỉ 2',
    addressLine2Placeholder: 'Căn hộ, tòa nhà, căn hộ, v.v.',
    city: 'Thành phố',
    state: 'Tiểu bang',
    zip: 'Mã Bưu Chính',
    textHome: 'Tên địa chỉ',
    placeholderHome: 'Bán tại. Trang chủ',
    ex: 'Thí dụ',
    ext: 'Ext.',
    cancel: 'Hủy bỏ',
    optional: ' (không bắt buộc)',
    primaryCompanionName: 'Tên của người đồng hành chính',
    relationship: 'Mối quan hệ',
    contactNumber: 'Số liên lạc',
    requireAssistance: 'Bạn có cần hỗ trợ?',
    selectTripType: 'Một chiều hay khứ hồi?',
    deviceEquipment: 'Thiết bị và Dụng cụ',
    roundTrip: 'Khứ hồi',
    roundTripPickUpTime: 'Bạn muốn được đón lúc mấy giờ trong cuộc hẹn?',
    oneWay: 'Một chiều',
    willCall: 'Sẽ gọi',
    willCallHint1: `Chọn 'Sẽ gọi' nếu bạn muốn gọi khi bạn đã xong`,
    willCallHint2: 'Lưu ý: thời gian chờ sẽ phụ thuộc vào tình trạng sẵn có của nhà cung cấp',
    numberOfCompanions: 'Số lượng bạn đồng hành',
    none: 'Không có',
    one: 'Một',
    two: 'Hai',
    verifyTripDetails: 'Xác nhận chi tiết của chuyến đi',
    submit: 'Nộp',
    healthInsuarance: 'Bảo hiểm của bạn',
    insuranceId: 'Mã thành viên',
    companions: 'Đối với một cuộc hẹn thời gian của',
    name: 'Tên',
    member: 'Thành viên',
    memberInfo: 'Thông tin thành viên',
    assistance: 'Sự trợ giúp',
    cancelWarning: `Mọi thông tin sẽ bị mất nếu bạn tiếp tục`,
    suggestedTime: 'Giờ đến đón mà bạn đề nghị',
    unavailablePlan: `Thật đáng tiếc, hiện tại Carecar khong co lam viec voi bao hiem suc khoe cua ban. Xin vui lòng liên lạc với hãng bảo hiểm của bạn.`,
    oneWayTrip: 'Một chiều',
    copy: 'Sao chép',
    print: 'In',
    pleaseSelectOne: 'Vui lòng chọn một',
    fieldCantBeEmpty: 'Trường này không thể để trống',
    roundTripHint: 'Hiện tại, chúng tôi chỉ cung cấp một chuyến xe trở lại địa điểm đón của bạn.',
    firstTrip: 'chuyến đi đầu tiên',
    returnTrip: 'Chuyến trở về',
    pickup: 'Dón',
    dropoff: 'Thả xuống',
    youChooseWillCall: `Bạn chọn 'Tôi sẽ gọi'`,
    noteWaitingTime: `Lưu ý: thời gian chờ sẽ tùy thuộc vào tình trạng sẵn có của người cung cấp`,
    basedOnADistanceOf: 'Dựa trên khoảng cách',
    withATravelTimeOf: 'Với thời gian di chuyển là',
    forAnAppointmentOf: 'Đối với một cuộc hẹn thời gian của',
    miles: '​​dặm',
    minutes: 'phút',
    returnPickupTime: 'Chọn thời gian cho chuyến trở về',
    placeholderHospital: 'ví dụ, bệnh viện',
    tripDetails: 'Chi tiết chuyến đi',
    enterPickupTime: 'Vui lòng nhập thời gian đón của bạn',
    successText: 'Yêu cầu của bạn đã được gửi thành công.',
    succesText2: 'Xin vui lòng lưu lại thông tin này vô hồ sơ của bạn.',
    notes: 'Ghi chú',
    notesPlaceholder:
      'Mã cổng là 1234 / Tòa nhà số 2 / Tôi sẽ cần một số trợ giúp để dỡ đồ đi bộ lên xe / Gặp bạn ngay trước địa chỉ tôi đã cung cấp, bên cạnh hộp thư / Tôi đội một chiếc mũ màu xanh lam / Ngoài ra, nó ở phía Bắc của đường phố',
    enterManualAddress: 'Bấm vào đây để nhập địa chỉ theo cách thủ công',
    companionAge: 'Bạn đồng hành phải trên 18 tuổi',
    other: 'Khác',
    note2Min: 'Lưu ý: Mất khoảng 2 phút',
    pleaseSpecify: 'Xin hãy chỉ ra cụ thể',
    placeholderSpecify: 'Phòng tập thể dục, Cửa hàng tạp hóa, v.v.',
    notesTip: 'Ghi chú giúp chúng tôi cung cấp mức độ dịch vụ cao nhất có thể',
    referenceNumber: 'Số tham chiếu của bạn',
    phoneNumberTip:
      'Nếu bạn đang lên lịch thay cho người khác, vui lòng nhập số tốt nhất để liên hệ với thành viên vào ngày hẹn',
    enterLastFiveDigits: 'Vui lòng nhập 5 chữ số cuối cùng',
  },
};
export default vi;
